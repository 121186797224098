import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { ContainedLinkBtn, DCBox, MainButton, MyTable, PageTitle, SBCBox, THS } from '../common/CommonElement';
import {
  api__deactivateMembership,
  api__getMembershipHistory,
  api__putMembershipSaleStatus,
} from '../../services/ManageUserService';
import { getOnlyDataByP1 } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { TableBody, TableCell, TableRow, Typography, styled } from '@mui/material';
import { refineMembershipHistory } from '../../Utils/manageArtistUtil';
import { MembershipHistoryType } from '../../Utils/types';
import { debounce } from 'lodash';
import { COLORS } from '../../Constant/COLORS';
import { ArtistDetailContext } from '../../pages/manage/ArtistDetail';
import { handleError } from '../../Utils/utils';
import ModalMsgBox from '../common/ModalMsgBox';
import { codeHandler } from '../../services/CommonService';
import { ERRORS } from '../../Constant/ERRORS';

export default function ArtistDetailMembership() {
  const { basicInfo, artistId } = useContext(ArtistDetailContext);
  const [membershipHistory, setMembershipHistory] = useState<MembershipHistoryType | null>(null);
  const [saleModal, setSaleModal] = useState('');
  const navigate = useNavigate();

  // 판매재개 및 판매중지를 하는 함수
  const putSaleStatus = async () => {
    try {
      const membershipSaleYn = basicInfo?.membershipSaleYn === 'Y' ? 'N' : 'Y';
      const result = await api__putMembershipSaleStatus(artistId || '', { membershipSaleYn });
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('판매 상태가 변경되었습니다.');
      window.location.reload();
    } catch (error: unknown) {
      handleError(error);
    }
  };
  const deactivate = () => {
    api__deactivateMembership(artistId || '')
      .then(res => {
        if (codeHandler(res)) {
          window.alert('멤버십이 비활성화 되었습니다.');
          window.location.reload();
        } else {
          window.alert(ERRORS[res.msg] || res.msg || '오류가 발생하였습니다.\n다시 시도하여주세요.');
        }
      })
      .catch(console.error);
  };
  const getMyInfo = debounce(() => {
    getOnlyDataByP1(api__getMembershipHistory, artistId || '', setMembershipHistory, refineMembershipHistory);
  }, 300);
  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [artistId]);
  if (membershipHistory === null) return <></>;
  return (
    <>
      {saleModal === 'saleStart' && (
        <ModalMsgBox
          title="멤버십 판매를 다시 시작하시겠어요?"
          setModal={bool => {
            setSaleModal('');
            if (!bool) putSaleStatus();
          }}
          extraBtn={'판매 재개'}
        ></ModalMsgBox>
      )}
      {saleModal === 'saleStop' && (
        <ModalMsgBox
          title="멤버십 판매를 중단하시겠어요?"
          setModal={bool => {
            setSaleModal('');
            if (!bool) putSaleStatus();
          }}
          extraBtn={'판매 중단'}
        ></ModalMsgBox>
      )}
      {saleModal === 'deactivate' && (
        <ModalMsgBox
          title="멤버십을 비활성화 하시겠어요?"
          setModal={bool => {
            setSaleModal('');
            if (!bool) deactivate();
          }}
          extraBtn={'비활성화'}
        ></ModalMsgBox>
      )}
      <SBCBox mt="120px" mb="24px">
        <PageTitle style={{ fontSize: '20px' }}>
          멤버십 히스토리 관리 / 총 판매금액:
          {membershipHistory.totalCash}원
        </PageTitle>
        {basicInfo?.artistUseYn === 'Y' ? (
          <DCBox>
            {basicInfo.membershipYn === 'Y' && (
              <>
                <MainButton
                  style={{ backgroundColor: COLORS.BORDER_GRAY, color: '#222' }}
                  onClick={() => {
                    setSaleModal('deactivate');
                  }}
                >
                  멤버십 비활성화
                </MainButton>
                <MainButton
                  onClick={() => {
                    setSaleModal(basicInfo.membershipSaleYn === 'Y' ? 'saleStop' : 'saleStart');
                  }}
                  sx={{ mx: '16px' }}
                  style={{ backgroundColor: COLORS.BORDER_GRAY, color: '#222' }}
                >
                  {basicInfo.membershipSaleYn === 'Y' ? '판매중단' : '판매재개'}
                </MainButton>
              </>
            )}
            <ContainedLinkBtn
              style={{ padding: '12px 18px' }}
              href={ROUTES.SIGNUPARTIST + `/membership-new/${artistId}`}
              target="_self"
              rel="noopener noreferrer"
            >
              멤버십 생성
            </ContainedLinkBtn>
          </DCBox>
        ) : (
          <Typography variant="h4">비활성화된 상태에서는 멤버십을 관리할 수 없습니다.</Typography>
        )}
      </SBCBox>
      <MyTable>
        <THS
          ths={[
            { name: '#', width: 40 },
            { name: '멤버십명', width: 120 },
            { name: '멤버십 상품 기간', width: 120 },
            { name: '판매 기간', width: 200 },
            { name: '상태', width: 60 },
            { name: '가입자 수', width: 80 },
            { name: '가격(원)', width: 80 },
            { name: '총 결제액(원)', width: 120 },
            { name: '관리', width: 80 },
          ]}
        />
        <TableBody>
          {(membershipHistory.MHistory || []).map((item, idx) => {
            return (
              <ThisRow key={`membership-history-${idx}`} className={basicInfo?.membershipYn === 'N' ? 'disabled' : ''}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.period}</TableCell>
                <TableCell>{item.salePeriod}</TableCell>
                <TableCell>{item.sellStatus}</TableCell>
                <TableCell>{item.userCnt}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.totalAmount}</TableCell>
                <TableCell>
                  <MainButton
                    onClick={() => {
                      navigate(ROUTES.SIGNUPARTIST + `/membership/${artistId}/${item.productId}`);
                    }}
                    className="active"
                    sx={{ minWidth: '50px' }}
                  >
                    상세
                  </MainButton>
                </TableCell>
              </ThisRow>
            );
          })}
        </TableBody>
      </MyTable>
    </>
  );
}

const ThisRow = styled(TableRow)({
  '&.disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.53)',
  },
});
