import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box } from "@mui/material";

import { DCBox, MainButton, ModalContentTypo, MyTable, PageTitle, SBCBox, THS } from "./common/CommonElement";
import ModalMsgBox from "./common/ModalMsgBox";
import LimitBox from "./common/LimitBox";
import Pages from "./common/Pages";
import ArtistSelectBox from "./common/ArtistSelectBox";

import MainLayout from "../layout/MainLayout";

import { delData, getArtistList, getListPL } from "../Utils/utils";
import { ArtistId } from "../Utils/types";

type IProps = {
  dataListInfo: any;
  setDataListInfo: any;
  hasArtistList?: boolean | undefined

  deleteModal: any;
  setDeleteModal: any;
  deleteCol: string;
  deleteDesc: string;
  deleteTitle?: string;

  mainTitle: string;
  pageTitle: string;
  route: string;
  createTxt?: string;
  isMailBox?: boolean | undefined;

  getApiFn: Function;
  delApiFn: Function;

  ths: { name: string, width: number }[];
  children: React.ReactNode
}

export default function CommonTable({
  dataListInfo,
  setDataListInfo,
  hasArtistList,

  deleteModal,
  setDeleteModal,
  deleteCol,
  deleteDesc,
  deleteTitle,

  mainTitle,
  pageTitle,
  route,
  createTxt,
  isMailBox,

  getApiFn,
  delApiFn,
  ths,
  children
}: IProps) {
  const navigate = useNavigate();
  const { page, limit, artistId } = useParams();

  const [aName, setAName] = useState("아티스트 선택");
  const [isDropped, setIsDropped] = useState(false);
  const [artistList, setArtistList] = useState<ArtistId[]>([]);

  const delMyData = async (id: string | number) => {
    delData(id, delApiFn, getMyList);
  }

  const getMyList = () => {
    getListPL(getApiFn, setDataListInfo, page || "1", limit || "10", hasArtistList ? artistId : undefined);
  }

  const changeSelect = (aVal: string, nameVal: string) => {
    navigate(
      route + `/1/${limit}${aVal !== "" && aVal !== "ALL" ? `/${aVal}` : ""}`
    )
    setAName(nameVal);
    setIsDropped(false);
  }

  useEffect(() => {
    if (artistId && artistList.length !== 0) {
      setAName(artistList.find((item) => item.artistId === artistId)?.artistNameKo || "아티스트 선택")
    }
    // eslint-disable-next-line
  }, [artistList])

  useEffect(() => {
    if (!hasArtistList) return;
    getArtistList(setArtistList);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [page, limit, artistId]);

  return (
    <>
      {deleteModal && (
        <ModalMsgBox title={deleteTitle || "게시물을 삭제하시겠어요?"} setModal={(bool) => {
          setDeleteModal(null);
          if (!bool) delMyData(deleteModal[deleteCol]);
        }} extraBtn={'삭제'}>
          <ModalContentTypo>
            ㆍ{deleteDesc === "content" ? "내용 : " : "제목 : "} {deleteModal[deleteDesc]}
          </ModalContentTypo>
        </ModalMsgBox>
      )}
      <MainLayout title={mainTitle}>
        <Box p="34px">
          <SBCBox mb='32px'>
            <PageTitle>{pageTitle}</PageTitle>
            <DCBox>
              {createTxt && <MainButton onClick={() => { navigate(route + (isMailBox ? "/default" : "/new")) }}>{createTxt}</MainButton>}
              {hasArtistList && <>
                <Box mr="28px" />
                <ArtistSelectBox
                  aName={aName} artistId={artistId || ""} isDropped={isDropped} artistList={artistList}
                  changeSelect={changeSelect} setIsDropped={setIsDropped}
                />
              </>}
            </DCBox>
          </SBCBox>
          <LimitBox limit={limit || "10"} page={page || "1"} route={route} subUrl={artistId ? `/${artistId}` : artistId} />
          <Box mt="18px">
            <MyTable>
              <THS ths={ths} />
              {children}
            </MyTable>
            {dataListInfo && <Pages pageInfo={dataListInfo} getList={(p) => {
              navigate(route + `/${String(p)}/${limit}` + (hasArtistList ? (artistId ? `/${artistId}` : "/") : ""))
            }}
            />}
          </Box>
        </Box>
      </MainLayout>
    </>
  )
}