import CryptoJS from "crypto-js";
import moment from "moment";
import { getCookie, removeCookie } from "./cookies";
import { NavigateFunction } from "react-router";
import { ArtistId, BackReponseType, ChangeLimitType, ChangeListInfo, CodeType, QueryParams } from "./types";
import { ERRORS } from "../Constant/ERRORS";
import { api__fileUpload, api__getAllArtistList, api__getCodes, codeHandler } from "../services/CommonService";
import { api__getMagazinesTargetList } from "../services/HomePostService";
import { api__putReportStatus } from "../services/RerportService";
import { debounce } from "lodash";
import { AxiosResponse } from "axios";
import { ApiError } from "./types";

// catch 문 내 에러 핸들링 함수
export const handleError = (error: unknown): void => {
  let errorMessage: string;

  if (error instanceof ApiError) {
    // ApiError 타입의 에러인 경우
    errorMessage = ERRORS[error.msg] || error.msg;
  } else if (error instanceof Error) {
    // 일반적인 Error 타입의 에러인 경우
    errorMessage = error.message;
  } else if (error === null || error === undefined) {
    // error가 null 또는 undefined인 경우
    errorMessage = "오류가 발생하였습니다.\n다시 시도하여주세요.";
  } else {
    // 알 수 없는 타입의 에러인 경우
    errorMessage = "알 수 없는 오류가 발생하였습니다.";
  }

  window.alert(errorMessage);
};

// 엔터를 누를 경우를 캐치하여 특정함수를 실행하는 함수
export const enterFn = (e: React.KeyboardEvent, okFn: () => void) => {
  const key = e.key || e.keyCode;
  if (key === "Enter" || key === 13) okFn();
};

// 해쉬 값으로 변경해주는 함수
export const toHash = (password: string) => {
  return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
};

export const regexPW = (pw: string) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^*]).+$/;
  return regex.test(pw);
};

// 로그아웃 시키는 함수
export const logout = (navigate: NavigateFunction) => {
  removeCookie("authCookie");
  removeCookie("myInfo");
  navigate("/auth");
};

export const logoutWithOutNavigate = debounce((code: string) => {
  switch (code) {
    case "T001":
      window.alert("잘못된 토큰 형식입니다.");
      break;
    case "T002":
      window.alert("유효시간이 만료된 토큰입니다.");
      break;
    case "T003":
      window.alert("접근 권한이 없습니다.");
      break;
    default:
      window.alert("알 수 없는 오류가 발생했습니다.");
      break;
  }
  removeCookie("authCookie");
  removeCookie("myInfo");
  window.location.href = process.env.REACT_APP_CLIENT_SERVER + "/auth";
}, 200);

// 갯수를 변경할 경우 동작하는 함수
export const changeLimit: ChangeLimitType = (e, listInfo, changeInfo, setChangeInfo) => {
  if (!listInfo) return;
  const { value } = e.target;
  const { currentPage } = listInfo;
  const { limit } = changeInfo;
  const currentStartIndex = limit * (currentPage - 1);
  const newPage = Math.floor(currentStartIndex / Number(value)) + 1;
  setChangeInfo({ ...changeInfo, page: newPage, limit: Number(value) });
};

// 날짜 포맷으로 출력해주는 함수
export const dateFormat = (date: string) => moment(date, "YYYYMMDD").format("YYYY년 MM월 DD일");
export const pointDate = (date: string) => moment(date).format("YYYY. MM. DD.");
// 시간 포맷으로 출력해주는 함수
export const timeFormat = (time: string) => moment(time, "HHmmss").format("HH시 mm분 ss초");
// 상세보기에서 사용하는 포맷
export const detailTimeFormat = (dateTime: string) => {
  if (dateTime === "") return "-";
  if (dateTime.length > 10) {
    return moment(dateTime).format("YYYY. MM. DD HH:mm");
  } else {
    dateTime = dateTime.replaceAll(" ", "");
    return moment(dateTime).format("YYYY. MM. DD");
  }
};

// 콤마 찍어주는 함수
export const addCommas = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

// 쿠키가 비었는지 체크해주는 함수
export const isEmptyCookie = (cookie: Object) => Object.keys(cookie).length === 0;

// 리스트를 받아오는 API 함수
export const getList = async (
  progressRef: React.MutableRefObject<boolean>,
  api__getFn: Function,
  changeInfo: ChangeListInfo,
  setDataListInfo: React.Dispatch<React.SetStateAction<any>>
) => {
  if (progressRef.current) return;
  progressRef.current = true;
  try {
    const result = await api__getFn(changeInfo);
    if (!result) return;

    const { code, msg } = result.data;
    if (code.includes("T")) {
      logoutWithOutNavigate(code);
      return;
    }
    if (code !== "S200") throw new Error(msg);
    setDataListInfo(result.data.data);
  } catch (error: unknown) {
    handleError(error);
  } finally {
    progressRef.current = false;
  }
};

// 리스트를 받아오는 API 함수
export const getListPL = debounce(
  (
    api__getFn: Function,
    setDataListInfo: React.Dispatch<React.SetStateAction<any>>,
    page: string,
    limit: string,
    artistId?: string,
    queryParams?: QueryParams
  ) => {
    api__getFn(page, limit, artistId ? artistId : undefined, queryParams)
      .then((res: any) => {
        if (codeHandler(res)) {
          setDataListInfo(res.data);
        } else {
          throw new Error(res.msg);
        }
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  },
  300
);

// 개별 정보를 받아오는 API 함수
export const getInfo = debounce(
  (api__getFn: (id: any) => Promise<AxiosResponse<any, any> | null>, id: string | undefined, setDataInfo: React.Dispatch<React.SetStateAction<any>>) => {
    api__getFn(id)
      .then((res: any) => {
        if (codeHandler(res)) {
          const { data } = res;
          for (let [key, value] of Object.entries(data)) {
            if (value === null) data[key] = "";
          }
          setDataInfo(res.data);
        } else {
          throw new Error(res.msg);
        }
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  },
  300
);

// 데이터를 삭제하는 API 함수
export const delData = async (id: string | number, api__delFn: Function, getMyList: Function) => {
  try {
    const result = await api__delFn(id);
    if (!result) return;
    const { code, msg } = result.data;
    if (code.includes("T")) {
      logoutWithOutNavigate(code);
      return;
    }
    if (code !== "S200") throw new Error(msg);
    getMyList();
  } catch (error: unknown) {
    handleError(error);
  }
};

export const uploadS3 = async (file: any, folderName: string) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder", folderName);

    const result = await api__fileUpload(formData);
    if (!result) return null;
    const { code, msg } = result.data;
    if (code !== "S200") throw new Error(msg);
    return result.data.data;
  } catch (error: unknown) {
    handleError(error);
  }
};

// 아티스트 리스트를 불러오는 함수
export const getArtistList = debounce((setArtistList: React.Dispatch<React.SetStateAction<ArtistId[]>>) => {
  api__getAllArtistList()
    .then((res) => {
      if (codeHandler(res)) {
        setArtistList(res.data);
      } else {
        throw new Error(res.msg);
      }
    })
    .catch((error: unknown) => {
      handleError(error);
    });
}, 300);

// 굿덕 매거진 리스트들을 불러오는 함수
export const getMagazinesList = async (setMagazineList) => {
  try {
    const result = await api__getMagazinesTargetList("?page=1");
    if (!result) return;
    const { code, msg } = result.data;
    if (code.includes("T")) {
      logoutWithOutNavigate(code);
      return;
    }
    if (code !== "S200") throw new Error(msg);

    setMagazineList(result.data.data.data);
  } catch (error: unknown) {
    handleError(error);
  }
};

// 각 key에 따른 코드 정보들을 불러오는 함수
export const getCodes = async (
  progressRef: React.MutableRefObject<boolean>,
  key: "ARTIST_GROUP_TYPE" | "CALENDAR_TAG" | "DOC_TYPE" | "GALLERY_TAG" | "MUSIC_PLATFORM_TYPE" | "REPORT_TYPE" | "INFO_ROOM_TYPE",
  setCodes: React.Dispatch<React.SetStateAction<any>>
) => {
  if (progressRef.current) return;
  progressRef.current = true;
  try {
    const result = await api__getCodes(key);
    if (!result) return;
    const { code, msg } = result.data;
    if (code !== "S200") throw new Error(msg);
    setCodes(
      result.data.data.reduce((obj, item: CodeType) => {
        obj[item.code] = item.codeName;
        return obj;
      }, {})
    );
  } catch (error: unknown) {
    handleError(error);
  } finally {
    progressRef.current = false;
  }
};

export const findElementWithClassAtClickPath = (event, className: string) => {
  let target = event.target;
  while (target) {
    if (target.classList && target.classList.contains(className)) {
      // 특정 클래스를 가진 요소를 찾은 경우 반환
      return target;
    }

    // 부모 요소로 이동
    target = target.parentNode;
  }

  // 특정 클래스를 가진 요소를 찾지 못한 경우 null 반환
  return null;
};

export const updateReportStatus = async (params: any, getMyList: Function) => {
  try {
    const result = await api__putReportStatus(params);
    if (!result) return;
    const { code, msg } = result.data;
    if (code.includes("T")) {
      logoutWithOutNavigate(code);
      return;
    }
    if (code !== "S200") throw new Error(msg);
    getMyList();
  } catch (error: unknown) {
    handleError(error);
  }
};

export const defaultHeader = {
  Accept: `application/json`,
  "Content-Type": `application/json`,
  // "ngrok-skip-browser-warning": "69420",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
};

export const makeHeader = () => {
  const cookie = getCookie("authCookie");
  if (isEmptyCookie(cookie)) {
    console.log("토큰 오류");
    return;
  }
  return { headers: { ...defaultHeader, Authorization: cookie.token } };
};

export const getOnlyDataByP1 = (apiFn: any, param1: any, setData: any, refineFn = (d: any) => d) => {
  apiFn(param1)
    .then((res: BackReponseType) => {
      if (codeHandler(res)) {
        setData(refineFn(res.data));
      } else {
        throw new Error(res.msg);
      }
    })
    .catch((error: unknown) => {
      handleError(error);
    });
};

export const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).trim().toLowerCase());
};
export const validDateTime = (dateTimeStr: string) => {
  // 정규 표현식을 사용하여 기본 형식 확인
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  if (!regex.test(dateTimeStr)) {
    return false;
  }

  // 날짜 및 시간 요소를 분리
  const [dateStr, timeStr] = dateTimeStr.split(" ");
  const [year, month, day] = dateStr.split("-").map(Number);
  const [hour, minute, second] = timeStr.split(":").map(Number);

  // 월 및 일의 유효성 확인
  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return false;
  }

  // 시간의 유효성 확인
  if (hour < 0 || hour > 23 || minute < 0 || minute > 59 || second < 0 || second > 59) {
    return false;
  }

  // Date 객체를 사용하여 유효한 날짜인지 확인
  const date = new Date(year, month - 1, day, hour, minute, second);
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day ||
    date.getHours() !== hour ||
    date.getMinutes() !== minute ||
    date.getSeconds() !== second
  ) {
    return false;
  }

  return true;
};

export const checkBookingStatus = (bookedYn: string, bookedDt: string | null, openYn: string) => {
  const isBooked = bookedYn === 'Y';
  const bookedTime = bookedDt ? new Date(bookedDt) : null;
  const now = new Date();
  const isPastBookedTime = bookedTime ? bookedTime <= now : false;
  const isPublished = openYn === 'Y' && isPastBookedTime;
  
  return {
    isBooked,
    bookedTime,
    isPastBookedTime,
    isPublished
  };
};