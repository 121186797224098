import { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { DeleteButton, DetailButton } from '../../Components/common/CommonElement';

import { api__delMailBoxes, api__getMailBoxesList } from '../../services/ArtistPageService';
import { MailBoxesInfo, MailBoxesListInfo, } from '../../Utils/types';
import { addCommas } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { COLORS } from '../../Constant/COLORS';
import CommonTable from '../../Components/CommonTable';

export default function MailBoxes() {
  const navigate = useNavigate();
  const { limit } = useParams();

  const [dataListInfo, setDataListInfo] = useState<MailBoxesListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<MailBoxesInfo | null>(null);

  return (
    <CommonTable
      dataListInfo={dataListInfo}
      setDataListInfo={setDataListInfo}
      hasArtistList

      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
      deleteCol="mailBoxId"
      deleteDesc="title"

      mainTitle='아티스트 페이지 관리 / 굿덕사서함'
      pageTitle={`굿덕사서함 / 게시물 수 : ${addCommas(dataListInfo?.totalCount || 0)}개`}
      route={ROUTES.MAILBOXES_MAIN}
      createTxt='디폴트 이미지 관리'
      isMailBox

      getApiFn={api__getMailBoxesList}
      delApiFn={api__delMailBoxes}

      ths={[
        { name: "#", width: 40 },
        { name: "아티스트명", width: 150 },
        { name: "계정 닉네임", width: 150 },
        { name: "재생수(회)", width: 80 },
        { name: "제목", width: 200 },
        { name: "업로드 일시", width: 120 },
        { name: "삭제 여부", width: 80 },
        { name: "관리", width: 120 },
      ]}
    >
      <TableBody>
        {dataListInfo && dataListInfo.data.map((item, uidx) => (
          <TableRow key={`user-${uidx}`}>
            <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
            <TableCell sx={{ color: item.artistUseYn === "N" ? COLORS.SIDEBAR_GRAY : "#222" }}>{item.artistNameKo}{item.artistUseYn === "N" ? "(비)" : ""}</TableCell>
            <TableCell>{item.uploaderNickname}</TableCell>
            <TableCell>{addCommas(item.playCnt)}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.rgstDt}</TableCell>
            <TableCell>{item.openYn === "Y" ? "N" : "Y"}</TableCell>
            <TableCell>
              <DetailButton onClick={() => { navigate(ROUTES.MAILBOXES_MAIN + "/" + item.mailBoxId) }}>상세</DetailButton>
              {item.openYn === "Y" && <DeleteButton onClick={() => { setDeleteModal(item) }}>삭제</DeleteButton>}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CommonTable>
  )
}