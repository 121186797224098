import { useEffect, useState, useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
  styled,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import {
  DetailButton,
  FlexBox,
  MainButton,
  MyTable,
  PageTitle,
  SBCBox,
  THS,
} from '../../Components/common/CommonElement';
import Pages from '../../Components/common/Pages';
import LimitBox from '../../Components/common/LimitBox';

import { api__getArtistList } from '../../services/ManageUserService';
import { ArtistListInfo, QueryParams } from '../../Utils/types';
import { addCommas, getCodes, getListPL } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';

const ThisBox = styled(Box)({
  borderRadius: '8px',
  width: '60px',
  margin: '0 auto',
  '&.inactive': {
    backgroundColor: '#fdebec',
  },
  '&.stop': {
    backgroundColor: '#dddddd',
  },
  '&.active': {
    backgroundColor: '#edf3ec',
  },
});

export default function ManageArtist() {
  const navigate = useNavigate();
  const params = useParams();
  const [dataListInfo, setDataListInfo] = useState<ArtistListInfo | null>(null);
  const [groupType, setGroupType] = useState<any>({});
  const [selectedMenu, setSelectedMenu] = useState<string>('A');

  const codeProcess = useRef(false);
  const { page, limit } = params;

  // selectedMenu에 따라 쿼리 파라미터를 반환하는 함수
  const getMembershipQueryParams = (selectedMenu: string): QueryParams => {
    let queryParams: QueryParams = {};

    switch (selectedMenu) {
      case 'A': // 전체
        queryParams = {}; // 모든 조건 없이 모든 데이터를 가져옴
        break;
      case 'N': // 비활성화 (membershipYn: 'N')
        queryParams = { membershipYn: 'N' }; // membershipYn 'N'인 경우 필터링
        break;
      case 'Y': // 판매중 (membershipYn: 'Y' && membershipSaleYn: 'Y')
        queryParams = { membershipYn: 'Y', membershipSaleYn: 'Y' };
        break;
      case 'P': // 판매중지 (membershipYn: 'Y' && membershipSaleYn: 'N')
        queryParams = { membershipYn: 'Y', membershipSaleYn: 'N' };
        break;
      default:
        queryParams = {}; // 기본적으로 모든 데이터를 가져옴
    }

    return queryParams;
  };

  // 멤버십 상태에 따른 리스트 요청
  const getMyList = async () => {
    // selectedMenu에 따라 쿼리 파라미터를 가져옴
    const queryParams = getMembershipQueryParams(selectedMenu);
    // queryParams 객체를 쿼리 문자열로 변환
    const searchParams = new URLSearchParams(queryParams as any).toString();

    getListPL(api__getArtistList, setDataListInfo, page || '1', limit || '10', searchParams);
  };

  const getMyCodes = () => {
    getCodes(codeProcess, 'ARTIST_GROUP_TYPE', setGroupType);
  };

  const prevParamsRef = useRef<QueryParams | null>(null);

  useEffect(() => {
    const queryParams = getMembershipQueryParams(selectedMenu);
    // 중복 호출 방지
    if (JSON.stringify(prevParamsRef.current) === JSON.stringify(queryParams)) return;
    // 쿼리 파라미터가 변경된 경우 API 호출 및 ref 업데이트
    prevParamsRef.current = queryParams;
    getMyList();
    // eslint-disable-next-line
  }, [selectedMenu]);

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    getMyCodes();
    // eslint-disable-next-line
  }, []);

  const membershipOptions = [
    { label: '전체', value: 'A' },
    { label: '판매중', value: 'Y' },
    { label: '비활성화', value: 'N' },
    { label: '판매중지', value: 'P' },
  ];

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedMenu(event.target.value);
    navigate(ROUTES.SIGNUPARTIST + '/1/' + (limit || '10'));
  };

  return (
    <MainLayout title="유저 관리 / 가입유저">
      <Box p="34px">
        <SBCBox mb="12px">
          <PageTitle>아티스트 관리</PageTitle>
          <MainButton
            onClick={() => {
              navigate(ROUTES.SIGNUPARTIST + '/new');
            }}
          >
            아티스트 생성
          </MainButton>
        </SBCBox>
        <FlexBox justifyContent="space-between">
          <LimitBox limit={limit || '10'} page={page || '1'} route={ROUTES.SIGNUPARTIST} />
          <Box width="300px">
            <FormControl fullWidth variant="outlined" margin="normal" size="small">
              <InputLabel id="membership-status-label">멤버십 상태</InputLabel>
              <Select
                labelId="membership-status-label"
                id="membership-status"
                label="멤버십 상태"
                value={selectedMenu}
                onChange={handleSelectChange}
              >
                {membershipOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </FlexBox>

        <Box mt="18px">
          <MyTable>
            <THS
              ths={[
                { name: '#', width: 40 },
                { name: '아티스트명', width: 200 },
                { name: '분류', width: 60 },
                { name: '입덕팬 수', width: 80 },
                { name: '피드', width: 80 },
                { name: '굿덕사서함', width: 80 },
                { name: '캘린더', width: 80 },
                { name: '굿톡', width: 80 },
                { name: '멤버십 상태', width: 120 },
                { name: '상태', width: 120 },
                { name: '관리', width: 80 },
              ]}
            />
            <TableBody>
              {dataListInfo &&
                dataListInfo.data.map((item, uidx) => (
                  <TableRow key={`user-${uidx}`}>
                    <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
                    <TableCell>{item.artistNameKo}</TableCell>
                    <TableCell>{groupType[item.groupType]}</TableCell>
                    <TableCell>{addCommas(item.duckCnt)}</TableCell>
                    <TableCell>{addCommas(item.artistFeedCnt)}</TableCell>
                    <TableCell>{addCommas(item.mailBoxCnt)}</TableCell>
                    <TableCell>{addCommas(item.calendarCnt)}</TableCell>
                    <TableCell>{addCommas(item.goodTalkCnt)}</TableCell>
                    <TableCell>
                      <ThisBox
                        className={
                          item.membershipYn === 'Y' ? (item.membershipSaleYn === 'Y' ? 'active' : 'stop') : 'inactive'
                        }
                      >
                        {item.membershipYn === 'Y'
                          ? item.membershipSaleYn === 'Y'
                            ? '판매중'
                            : '판매중지'
                          : '비활성화'}
                      </ThisBox>
                    </TableCell>
                    <TableCell>
                      <ThisBox className={item.artistUseYn === 'Y' ? 'active' : 'inactive'}>
                        {item.artistUseYn === 'Y' ? '활성화' : '비활성화'}
                      </ThisBox>
                    </TableCell>
                    <TableCell>
                      <DetailButton
                        onClick={() => {
                          navigate(ROUTES.SIGNUPARTIST + '/' + item.artistId);
                        }}
                      >
                        상세
                      </DetailButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </MyTable>
          {dataListInfo && (
            <Pages
              pageInfo={dataListInfo}
              getList={p => {
                navigate(ROUTES.SIGNUPARTIST + `/${String(p)}/${limit}`);
              }}
            />
          )}
        </Box>
      </Box>
    </MainLayout>
  );
}
