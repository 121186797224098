import { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Box } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { api__delArtistFeed, api__getArtistFeedInfo } from '../../services/ArtistPageService';
import { ArtistFeedDetailInfo, ArtistFeedInfo } from '../../Utils/types';
import { BackBtn, DCBox, DetailLayoutBox, FlexBox, InfoComp, InfoContextComp, InfoImageComp, InfoURLComp, MainButton, PageTitle } from '../../Components/common/CommonElement';
import { delData, detailTimeFormat, getInfo } from '../../Utils/utils';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import ImagePopup from '../../Components/common/ImagePopup';
import DetailArtistName from '../../Components/common/DetailArtistName';


export default function ArtistFeedDetail() {
	const [detailInfo, setDetailInfo] = useState<ArtistFeedDetailInfo | null>(null);
	const { artistFeedId } = useParams();
	const [deleteModal, setDeleteModal] = useState<ArtistFeedInfo | null>(null);
	const [imgModal, setImgModal] = useState("");

	const getMyInfo = () => {
		getInfo(api__getArtistFeedInfo, artistFeedId, setDetailInfo);
	}

	const delMyData = (id: string) => {
		delData(id, api__delArtistFeed, getMyInfo);
	}

	useEffect(() => {
		getMyInfo();
		// eslint-disable-next-line
	}, [artistFeedId]);
	if (detailInfo === null) return <MainLayout title="아티스트 페이지 관리 / 아티스트 피드"></MainLayout>
	return (
		<>
			{imgModal && <ImagePopup imgModal={imgModal} setImgModal={setImgModal} imgList={detailInfo.imgList} />}
			{deleteModal && (
				<ModalMsgBox title="게시물을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(null);
					if (!bool) delMyData(deleteModal.artistFeedId);
				}} extraBtn={'삭제'}>
				</ModalMsgBox>
			)}
			<MainLayout title="아티스트 페이지 관리 / 아티스트 피드">
				<DetailLayoutBox>
					<DCBox mb="34px">
						<BackBtn />
						<PageTitle>아티스트 피드 게시물 상세</PageTitle>
					</DCBox>
					<Box mb="40px">
						<DetailArtistName artistNameKo={detailInfo.artistNameKo} artistUseYn={detailInfo.artistUseYn} />
						<InfoComp title='업로드 일시' value={detailTimeFormat(detailInfo.rgstDt)} />
						<InfoComp title='계정 닉네임' value={detailInfo.uploaderNickname} />
						<InfoContextComp title='내용' value={detailInfo.content} />
						<InfoImageComp title='이미지' imageList={detailInfo.imgList} setImgModal={setImgModal} />
						<InfoURLComp title='링크버튼 URL' url={detailInfo.linkUrl || ""} readOnly />
						<InfoURLComp title='유튜브 URL' url={detailInfo.youtubeUrl || ""} readOnly />
						<InfoComp title='삭제여부' value={detailInfo.openYn === "Y" ? "N" : "Y"} />
					</Box>
					{detailInfo.openYn === "Y" && <FlexBox justifyContent={'flex-end'}>
						<MainButton onClick={() => { setDeleteModal(detailInfo) }}>삭제</MainButton>
					</FlexBox>}
				</DetailLayoutBox>
			</MainLayout>
		</>
	);
}
