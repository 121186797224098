import { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Box, Typography } from '@mui/material';

import MainLayout from "../../layout/MainLayout";
import { api__delMailBoxes, api__getMailBoxInfo } from '../../services/ArtistPageService';
import { MailBoxesDetailInfo } from '../../Utils/types';
import { addCommas, delData, detailTimeFormat, getInfo } from '../../Utils/utils';
import { BackBtn, DCBox, FlexBox, InfoComp, InfoTextBox, InfoTitleBox, MainButton, OutLinedLinkBtn, PageTitle, StretchBox } from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import DetailArtistName from '../../Components/common/DetailArtistName';


export default function MailBoxDetail() {
	const [detailInfo, setDetailInfo] = useState<MailBoxesDetailInfo>({
		openYn: "Y",
		rgstDt: '',
		title: '',
		artistNameKo: '',
		artistUseYn: "Y",
		audioDuration: 0,
		audioFileName: '',
		audioFileUrl: '',
		mailBoxId: '',
		playCnt: 0,
		uploaderNickname: ''
	});
	const [deleteModal, setDeleteModal] = useState<MailBoxesDetailInfo | null>(null);

	const { mailBoxId } = useParams();

	const getMyInfo = async () => {
		getInfo(api__getMailBoxInfo, mailBoxId, setDetailInfo);
	}

	const delMyData = (id: string) => {
		delData(id, api__delMailBoxes, getMyInfo);
	}

	useEffect(() => {
		getMyInfo();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{deleteModal && (
				<ModalMsgBox title="게시물을 삭제하시겠어요?" setModal={(bool) => {
					setDeleteModal(null);
					if (!bool) delMyData(deleteModal.mailBoxId);
				}} extraBtn={'삭제'}>
				</ModalMsgBox>
			)}
			<MainLayout title="아티스트 페이지 관리 / 굿덕사서함">
				<Box p="38px 34px" pb="120px">
					<DCBox mb='34px'>
						<BackBtn />
						<PageTitle>굿덕사서함 상세</PageTitle>
					</DCBox>
					<Box mb='40px'>
						<DetailArtistName artistNameKo={detailInfo.artistNameKo} artistUseYn={detailInfo.artistUseYn} />
						<InfoComp title='업로드 일시' value={detailTimeFormat(detailInfo.rgstDt)} />
						<InfoComp title='계정 닉네임' value={detailInfo.uploaderNickname} />
						<InfoComp title='제목' value={detailInfo.title} />
						<InfoComp title='재생수' value={addCommas(detailInfo.playCnt)} />
						<StretchBox>
							<InfoTitleBox>오디오 파일</InfoTitleBox>
							<InfoTextBox style={{ justifyContent: "space-between", paddingRight: '12px' }}>
								<Box>
									<Typography variant='h4' mb="12px">{detailInfo.audioFileName}</Typography>
									<AudioPlayer
										src={detailInfo.audioFileUrl}
										showJumpControls={false}
										style={{ width: '400px' }}
									/>
								</Box>
								<OutLinedLinkBtn href={detailInfo.audioFileUrl} download={true} target='_blank'>다운로드</OutLinedLinkBtn>
							</InfoTextBox>
						</StretchBox>
						<InfoComp title='삭제여부' value={detailInfo.openYn === "Y" ? "N" : "Y"} />
					</Box>
					{detailInfo.openYn === "Y" && <FlexBox justifyContent={'flex-end'}>
						<MainButton onClick={() => { setDeleteModal(detailInfo) }}>삭제</MainButton>
					</FlexBox>}
				</Box>
			</MainLayout>
		</>
	);
}
