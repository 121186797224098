import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  DCBox,
  ImageBox,
  InfoComp,
  InfoImageBox,
  InfoTextBox,
  InfoTitleBox,
  MainButton,
  PageTitle,
  SBCBox,
  StretchBox,
} from '../../Components/common/CommonElement';
import { useParams } from 'react-router';
import { ArtistId, MembershipType } from '../../Utils/types';
import { ERRORS } from '../../Constant/ERRORS';
import { api__getMembershipDetail, api__putMembershipInfos } from '../../services/ManageUserService';
import ModalMsgBox from '../../Components/common/ModalMsgBox';
import { addCommas, detailTimeFormat, getArtistList } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';
import { debounce } from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyUploadAdapter from '../../Components/common/MyUploadAdapter';
import { handleError } from '../../Utils/utils';

export default function ArtistMembershipManage() {
  const { artistId, productId } = useParams();
  const [dataInfo, setDataInfo] = useState<MembershipType>({
    amount: 0,
    imgUrl: '',
    membershipSaleYn: 'Y',
    name: '',
    period: -1,
    saleEndDt: '',
    saleStartDt: '',
    status: '',
    userCnt: 0,
    productId: '',
    benefits: '',
    detail: '',
  });
  const [artistList, setArtistList] = useState<ArtistId[]>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // 일반 멤버십 정보 변경 함수
  const changeEditState = (value: string | number, target: string) => {
    if (!editMode) return;
    if (!isEdit) setIsEdit(true);
    setDataInfo(prev => {
      const clone = { ...prev };
      clone[target] = value;
      return clone;
    });
  };
  // 수정사항 모달창을 띄우기 전 사전에 실시하는 유효성 검사
  const checkValidation = () => {
    try {
      const { benefits, detail } = dataInfo;
      if (benefits.trim() === '') throw new Error('membership_benefits');
      if (detail.trim() === '') throw new Error('membership_detail');
      setEditModal(true);
    } catch (error: unknown) {
      handleError(error);
    }
  };
  const putMembershipInfos = async () => {
    try {
      const { benefits, detail } = dataInfo;
      const result = await api__putMembershipInfos(artistId || '', { productId, benefits, detail });
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      window.alert('수정이 완료되었습니다.');
      window.location.replace(ROUTES.SIGNUPARTIST + '/' + artistId);
    } catch (error: unknown) {
      handleError(error);
    }
  };
  // 멤버십 정보를 가져온느 함수
  const getData = debounce(async () => {
    try {
      const result = await api__getMembershipDetail(artistId || '', productId || '');
      if (!result) return;
      const { code, msg } = result.data;
      if (code !== 'S200') throw new Error(msg);
      setDataInfo(result.data.data);
      const { userCnt, amount } = result.data.data;
      setTotalAmount(userCnt * amount);
    } catch (error: unknown) {
      handleError(error);
    }
  }, 300);
  useEffect(() => {
    getData();
    getArtistList(setArtistList);
    // eslint-disable-next-line
  }, []);

  const editActive = () => {
    if (editMode && isEdit) {
      checkValidation();
    } else {
      setEditMode(prev => !prev);
    }
  };
  function detailUploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, 'membership-detail');
    };
  }

  return (
    <>
      {editModal && (
        <ModalMsgBox
          title="수정사항을 적용하시겠어요?"
          setModal={bool => {
            setEditModal(false);
            if (!bool) putMembershipInfos();
          }}
          extraBtn={'적용'}
        ></ModalMsgBox>
      )}
      <MainLayout
        title={`유저 관리 / 아티스트 / ${artistList.find(item => item.artistId === artistId)?.artistNameKo || ''}`}
      >
        <Box p="38px 34px" pb="120px">
          <SBCBox mb="34px">
            <DCBox>
              <BackBtn />
              <PageTitle>
                멤버십 관리 - {artistList.find(item => item.artistId === artistId)?.artistNameKo || ''}
              </PageTitle>
            </DCBox>
            <MainButton onClick={editActive}>{editMode ? '수정 적용' : '수정'}</MainButton>
          </SBCBox>
          <Box mb="40px">
            <StretchBox>
              <InfoTitleBox>멤버십명</InfoTitleBox>
              <InfoTextBox>{dataInfo.name}</InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>멤버십 가격(원)</InfoTitleBox>
              <InfoTextBox>{dataInfo.amount}</InfoTextBox>
            </StretchBox>
            <StretchBox>
              <InfoTitleBox>멤버십 상품기간</InfoTitleBox>
              <InfoTextBox>{dataInfo.period}일</InfoTextBox>
            </StretchBox>
            <InfoComp
              title="판매기간"
              value={`${detailTimeFormat(dataInfo.saleStartDt)} - ${dataInfo.status === '002' ? detailTimeFormat(dataInfo.saleEndDt) : '현재'}`}
            />
            <StretchBox>
              <InfoTitleBox>배지 이미지</InfoTitleBox>
              <InfoImageBox>
                <Box width={120} height={120}>
                  <ImageBox>{dataInfo.imgUrl !== '' && <img src={dataInfo.imgUrl} alt="배지 이미지" />}</ImageBox>
                </Box>
              </InfoImageBox>
            </StretchBox>
            <InfoComp title="가입자 수" value={addCommas(dataInfo.userCnt)} />
            <InfoComp title="총 결제액(원)" value={addCommas(totalAmount)} />
            <InfoComp
              title="판매 상태"
              value={dataInfo.status === '002' ? '판매만료' : dataInfo.membershipSaleYn === 'Y' ? '판매중' : '판매중지'}
            />
          </Box>
          <SBCBox sx={{ alignItems: 'flex-start' }}>
            <Box width={445} mr="24px">
              <InfoTitleBox sx={{ width: '100%' }}>멤버십 혜택</InfoTitleBox>
              <CKEditor
                editor={ClassicEditor}
                data={dataInfo.benefits || ''}
                onChange={(event, editor) => {
                  changeEditState(editor.getData(), 'benefits');
                }}
                disabled={!editMode}
              />
            </Box>
            <Box width={445}>
              <InfoTitleBox sx={{ width: '100%' }}>상품상세</InfoTitleBox>
              <CKEditor
                editor={ClassicEditor}
                data={dataInfo.detail || ''}
                config={{ extraPlugins: [detailUploadPlugin] }}
                onChange={(event, editor) => {
                  changeEditState(editor.getData(), 'detail');
                }}
                disabled={!editMode}
              />
            </Box>
          </SBCBox>
        </Box>
      </MainLayout>
    </>
  );
}
