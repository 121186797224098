import { useState, useEffect, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router';

import MainLayout from '../../layout/MainLayout';
import {
  BackBtn,
  DCBox,
  FEBox,
  InfoComp,
  InfoTextBox,
  InfoTitleBox,
  MainButton,
  ModalContentTypo,
  PageTitle,
  StretchBox,
} from '../../Components/common/CommonElement';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

import { detailTimeFormat, getInfo, pointDate, validDateTime } from '../../Utils/utils';
import { api__getUserDetail, api__putExpiredDate, api__putNickname } from '../../services/ManageUserService';
import { UserBasicInfo, UserDetailInfo, UserMembershipInfo } from '../../Utils/types';
import { codeHandler } from '../../services/CommonService';

export default memo(function UserDetail() {
  const [detailInfo, setDetailInfo] = useState<UserDetailInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState('');
  const [changeModal, setChangeModal] = useState<UserBasicInfo | null>(null);
  const [changeError, setChangeError] = useState(false);

  const { uid } = useParams();

  const getMyInfo = () => {
    getInfo(api__getUserDetail, uid, setDetailInfo);
  };

  useEffect(() => {
    getMyInfo();
    // eslint-disable-next-line
  }, [uid]);

  const delMyData = async (id: string) => {
    // delData(id, api__delCalendar, getMyList);
  };
  const changeNickName = () => {
    api__putNickname(uid!, changeModal?.userNickname || '', changeModal!.userProfileId)
      .then(res => {
        if (codeHandler(res)) {
          window.alert('변경 완료되었습니다.');
          window.location.reload();
        } else {
          setChangeError(true);
        }
      })
      .catch(console.error);
  };

  const changeExpiredDate = (value: string, idx: number) => {
    setDetailInfo(prev => {
      if (!prev) return prev;
      const temp = { ...prev };
      temp.userMembershipInfoList[idx].endDt = value;
      return temp;
    });
  };
  const changeExiredDateAPI = (item: UserMembershipInfo) => {
    if (validDateTime(item.endDt)) {
      api__putExpiredDate(uid!, item.endDt.split(' ')[0], item.membershipId)
        .then(res => {
          if (codeHandler(res)) {
            window.alert('변경 완료되었습니다.');
            window.location.reload();
          }
        })
        .catch(console.error);
    } else {
      window.alert('날짜 형식을 확인해주세요.');
    }
  };

  // 멤버십 상태를 계산하는 함수(구매 취소 여부, 기간 만료 여부)
  const getMembershipStatus = (cancelled: boolean, endDt: string): string => {
    if (cancelled) {
      return '구매취소(환불)';
    }
    const today = new Date();
    const endDate = new Date(endDt);
    if (today > endDate) {
      return '기간만료';
    } else {
      return '활성화';
    }
  };

  if (!detailInfo) return <MainLayout title="유저 관리 / 가입유저"></MainLayout>;
  return (
    <>
      {deleteModal !== '' && (
        <ModalMsgBox
          title="계정을 삭제하시겠어요?"
          setModal={bool => {
            setDeleteModal('');
            if (!bool) delMyData(uid || '');
          }}
          extraBtn={'삭제'}
        >
          <ModalContentTypo>ㆍ이메일 ID : {deleteModal}</ModalContentTypo>
        </ModalMsgBox>
      )}
      {changeModal && (
        <ModalMsgBox
          title="닉네임 변경"
          setModal={bool => {
            if (!bool) {
              changeNickName();
            } else {
              setChangeModal(null);
            }
          }}
          extraBtn={'변경'}
        >
          <ModalContentTypo>ㆍ이메일 ID : {changeModal.email}</ModalContentTypo>
          <ModalContentTypo>ㆍ입덕 아티스트 : {changeModal.artistNameKo}</ModalContentTypo>
          <DCBox mt="24px">
            <ModalContentTypo mr="8px">ㆍ닉네임 :</ModalContentTypo>
            <input
              type="text"
              value={changeModal.userNickname || ''}
              onChange={e => {
                setChangeModal({ ...changeModal, userNickname: e.target.value });
                setChangeError(false);
              }}
              style={{
                borderRadius: '6px',
                border: '1px solid #ccc',
                padding: '4px 8px',
                fontWeight: 600,
              }}
            />
          </DCBox>
          <Box height={28}>
            {changeError && (
              <ModalContentTypo ml="72px" style={{ color: 'red', fontSize: '12px' }}>
                사용 불가능한 닉네임입니다.(중복 혹은 금지어)
              </ModalContentTypo>
            )}
          </Box>
        </ModalMsgBox>
      )}
      <MainLayout title="유저 관리 / 가입유저">
        <Box p="38px 34px" pb="120px">
          <Box mb="12px">
            <DCBox mb="43px">
              <BackBtn />
              <PageTitle>유저 상세</PageTitle>
            </DCBox>
            <PageTitle style={{ fontSize: '20px' }}>기본 정보</PageTitle>
          </Box>
          <Box mb="37px">
            <InfoComp title="이메일ID" value={detailInfo.userBasicInfoList[0].email} />
            <InfoComp title="회원가입일" value={pointDate(detailInfo.userBasicInfoList[0].userRgstDt)} />
            <StretchBox>
              <InfoTitleBox>입덕정보</InfoTitleBox>
              <InfoTextBox style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                {detailInfo.userBasicInfoList.map((item, idx) => {
                  if (item.userNickname === null) return <></>;
                  return (
                    <DCBox key={`duck_data_${idx}`} mb="10px">
                      <Typography variant="body1">{idx + 1}.</Typography>
                      <Typography variant="body1" mx="20px">
                        {item.artistNameKo}
                      </Typography>
                      <Typography variant="body1">닉네임 : {item.userNickname}</Typography>
                      <Typography variant="body1" mx="20px">
                        입덕일자 : {pointDate(item.duckRgstDt || '')}
                      </Typography>
                      <MainButton
                        onClick={() => {
                          setChangeModal(item);
                        }}
                        sx={{ borderRadius: '6px', padding: '8px 12px', fontSize: '14px' }}
                      >
                        닉네임 변경
                      </MainButton>
                    </DCBox>
                  );
                })}
              </InfoTextBox>
            </StretchBox>
            <InfoComp
              title="입덕한 아티스트 수"
              value={String(
                detailInfo.userBasicInfoList.reduce((acc, cur) => {
                  if (cur.userNickname !== null) acc += 1;
                  return acc;
                }, 0),
              )}
            />
          </Box>
          <PageTitle style={{ fontSize: '20px', marginTop: '120px', marginBottom: '24px' }}>멤버십 가입 정보</PageTitle>
          <Box>
            {detailInfo.userMembershipInfoList.map((item, idx) => (
              <StretchBox key={`membership_info_${idx}`}>
                <InfoTitleBox>{item.name}</InfoTitleBox>
                <InfoTextBox style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="body1">ㆍ멤버십명 : {item.name}</Typography>
                  <Typography variant="body1">ㆍ해당 아티스트명 : {item.artistNameKo}</Typography>
                  <Typography variant="body1">
                    ㆍ멤버십 유효기간 : {detailTimeFormat(item.startDt)} ~{' '}
                    {process.env.REACT_APP_ENV === 'PROD' ? (
                      detailTimeFormat(item.endDt)
                    ) : (
                      <>
                        <input
                          type="text"
                          value={item.endDt}
                          onChange={e => {
                            changeExpiredDate(e.target.value, idx);
                          }}
                        />
                        <MainButton
                          onClick={() => {
                            changeExiredDateAPI(item);
                          }}
                          sx={{ padding: '4px 8px', marginLeft: '12px' }}
                        >
                          만료일자 변경
                        </MainButton>
                      </>
                    )}
                  </Typography>
                  <Typography variant="body1">ㆍ가입일 : {detailTimeFormat(item.rgstDt)}</Typography>
                  <Typography variant="body1">ㆍ상태 : {getMembershipStatus(item.cancelled, item.endDt)}</Typography>
                </InfoTextBox>
              </StretchBox>
            ))}
          </Box>
          <FEBox mt="24px">
            <MainButton
              onClick={() => {
                setDeleteModal(detailInfo.userBasicInfoList[0].email);
              }}
            >
              계정 삭제
            </MainButton>
          </FEBox>
        </Box>
      </MainLayout>
    </>
  );
});
