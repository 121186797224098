import { useEffect } from 'react';
import 'react-day-picker/dist/style.css';
import { Route, Routes } from 'react-router';
import { ROUTES } from './Utils/routes';
import { getCookie } from './Utils/cookies';
import { isEmptyCookie } from './Utils/utils';
import { ThemeProvider, createTheme } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ko';
import './App.css'

import Home from './pages/Home';

import HeaderInfo from './pages/header/Info';
import HeaderMaster from './pages/header/Master';

import Reply from './pages/reply';
import { BrowserRouter } from 'react-router-dom';
import { partnersMenuList } from './config/PartnersRoutes';
import { temrsMenuList } from './config/TermsRoutes';
import { userManageMenuList } from './config/UserManageRoutes';
import { homeContentsMenuList } from './config/HomeContentsRoutes';
import { artistPageMenuList } from './config/ArtistPageRoutes';
import { calendarMenuList } from './config/CalendarRoutes';
import { reportMenuList } from './config/ReportRoutes';
import { badgeMenuList } from './config/BadgeRoutes';
import { authMenuList } from './config/authRoutes';


function App() {
  const renderRoutes = (list: any, keyString: string) => (
    list.map((m: any, i: number) => (
      <Route
        key={`${keyString}_${i}`}
        path={m.path}
        Component={m.component}
      />
    ))
  )

  useEffect(() => {
    const cookie = getCookie("authCookie");
    if (isEmptyCookie(cookie)) {
      const exceptUrl = ["/auth", "/auth/reset-password", "/email/verify"];
      if (!exceptUrl.includes(window.location.pathname)) {
        window.location.href = "/auth"
        return;
      }
    }
    const authList = ["/auth", "/auth/reset-password", "/email/verify"];
    if (!isEmptyCookie(cookie) && authList.includes(window.location.pathname)) {
      window.alert("비정상적인 접근으로 홈화면으로 돌아갑니다.");
      window.location.href = "/";
      return;
    }
    // eslint-disable-next-line
  }, [location.pathname])

  moment.locale("ko");
  const theme = createTheme({
    palette: {
      primary: {
        main: '#F57F17'
      },
      secondary: {
        main: '#47B881'
      },
      error: {
        main: '#F26460'
      }
    },
    typography: {
      fontFamily: [
        'Pretendard Variable',
        'Pretendard',
        '-apple-system',
        'BlinkMacSystemFont',
        'system-ui',
        'Roboto',
        'Helvetica Neue',
        'Segoe UI',
        'Apple SD Gothic Neo',
        'Noto Sans KR',
        'Malgun Gothic',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'sans-serif'
      ].join(','),
      h1: {
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      h2: {
        fontWeight: 700,
        fontSize: '29px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      h3: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      h4: {
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      h5: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      body1: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      body2: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      caption: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.54)'
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '150%',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      h6: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '200%',
        color: '#000'
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '160%',
        color: 'rgba(0, 0, 0, 0.87)'
      }
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.color === 'primary' && {
              color: 'rgba(0, 0, 0, 0.6)'
            },
              ownerState.color === 'secondary' && {
                color: 'rgba(0, 0, 0, 0.38)'
              },
              ownerState.className === 'disabled' && {
                color: 'rgba(0, 0, 0, 0.2)'
              })
          })
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.size === 'small' && {
              padding: '0',
              height: '29px'
            },
              ownerState.size === 'medium' && {
                padding: '0',
                height: '34px'
              },
              ownerState.size === 'large' && {
                padding: '0',
                height: '37px'
              })
          })
        }
      }
    }
  });
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>

            <Route path="/" Component={Home} />
            <Route path="/info" Component={HeaderInfo} />
            <Route path="/master/:page/:limit" Component={HeaderMaster} />
            {renderRoutes(authMenuList, "auth")}
            {renderRoutes(partnersMenuList, "partners")}
            {renderRoutes(temrsMenuList, "terms")}
            {renderRoutes(userManageMenuList, "user-manage")}
            {renderRoutes(homeContentsMenuList, "home-contents")}
            {renderRoutes(artistPageMenuList, "artist-page")}
            {renderRoutes(calendarMenuList, "calendar")}
            <Route path={ROUTES.REPLY_MAIN + "/:page/:limit/:contentsType?/:artistId?"} Component={Reply} />
            {renderRoutes(badgeMenuList, "badge")}
            {renderRoutes(reportMenuList, "report")}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
